@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  box-sizing: border-box;
}

svg {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 768px) {
  svg {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 576px) {
  svg {
    width: 15px;
    height: 15px;
  }
}

.active {
  color: #f78e0b;
}

#show {
  visibility: visible;
}

#hide {
  visibility: hidden;
}
